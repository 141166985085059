import React from 'react'
import SliderTestimonial from '../../component/slidercomponent/SliderTestimonial'
import { Link } from "react-router-dom";


const Testimonial = () => {
    return (
        <>

            <section className="testimonial-one" style={{overflow:"hidden", marginTop:"-120px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="testimonial-one__left">
                                <div className="section-title text-left">
                                    <span className="section-title__tagline" style={{ textTransform: "capitalize" }}>Adarsh Sales Pvt. Ltd.</span>
                                    <h2 className="section-title__title" style={{fontSize:"50px"}}>Testimonial</h2>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonial-one__right">
                                <div className="testimonial-one__carousel  owl-theme thm-owl__carousel">

                                    <SliderTestimonial />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Testimonial