import React from 'react'
import { Link } from "react-router-dom";


const BlockBoards = () => {
    return (
        <>


            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Block Boards</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="index.html">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Block Boards</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="../assets/ply/Block Boards.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "28px" }}>Sandwich Blockboards</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SANDWICH Blockboard is ideal for home and office furniture, segments, and shades. The synthetically treated and prepared imported pine wood is handled in our processing plant. It has been explicitly made with a magnificent nail holding limit that permits the Blockboard to be collected into any furniture easily.</p>
                                        <ul className="list-unstyled project-detsils__challenge-list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Supreme Boards</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling Water Proof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>19mm & 25mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Excellent</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling Water Proof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Smooth surface</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face thickness 3mm or 5mm
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Available with full-thickness.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Absorbs less adhesive
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Easy to paint</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available :- 19mm & 25mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available :- Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face :- HDHMR
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Filler :- Pinewood.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding :- PF resin as per BIS standards.</p>
                                                </div>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density :- Above 700 kg/cm3</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content :- 6-8%

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength :- Excellent
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse :- Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty Any :- manufacturing defects as per warranty certificate</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}>SUPREME BOARDS</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SUPREME Grade Block boards are waterproof block boards constructed with PF Resin, according to IS norms. Fillers inside these boards are filled to spread the resin equally which provide excellent bonding and strength.</p>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/SUPREME BOARDS.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling Water Proof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Smooth surface</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Available with full-thickness
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 19mm & 25mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available : Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face : Gurjan
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Filler : Pinewood</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding : PF resin as per BIS standards.

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : Above 700 kg/cm3</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content : 6-8%

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength : Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse : Excellent
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per warranty certificate</p>
                                                </div>
                                            </li>
                                            
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>
            </div>

        </>
    )
}

export default BlockBoards