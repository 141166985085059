import React from 'react'
import { Link } from "react-router-dom";


const Plywood = () => {
    return (
        <>


            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Plywood</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="index.html">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Plywood</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="../assets/ply/plywood-500x500.webp" alt />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "28px" }}>Sandwich Ply (IS:303) – Fully Waterproof</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SANDWICH Ply is considered to be the most exclusive product of ASPL. The multi-use of the item makes it quite possibly the most favoured brands on the lookout. Imported high-thickness lumber adds additional thickness and thickness to the item, which gives better toughness and dimensional security. Plus this plywood contains the properties of HDHMR and the strength of plywood, which makes it carpenter friendly and popular in the market. This handle is bubbling waterproof, which expands the life span of the utilize. The sandwich series are being praised well by our clients.</p>
                                        <ul className="list-unstyled project-detsils__challenge-list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>07 Year Guarantee</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Fully Water Proof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>IS : 303</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Commercial Doors</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Residential Doors</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling waterproof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Smooth surface</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face thickness 3mm or 5mm
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Properties of HDHMR and strength of plywood</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Absorbs less adhesive
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Easy to paint</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available :- 19mm & 12mm (more up to 40mm)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available :- Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face:- HDHMR
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Core :- All red hard core</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding :- PF resin as per BIS standards</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density :- Above 750 kg/cm3</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content :- 8-12%</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength :- Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse :- Excellent
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test :- Confirms to IS:303</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty :- Any manufacturing defects as per warranty certificate</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS :- IS:303</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}>SUPREME PLY (IS:303) BWP</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SUPREME Grade Plywood is high-quality Water Proof plywood. It is constructed from a red core (hardcore) with a soft core in the centre to increase its nail and screw holding capacity. It is constructed with PF resin which allows it to develop its waterproof properties. It is also constructed according to IS:303 norms. It is popular in the market for its strength and waterproofing.</p>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/SUPREME PLY.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling waterproof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>15 layered ply for 19 mm (Additional 2 layers for other thickness)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>13 layered ply for 16 mm (Additional 2 layers for other thickness)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>11 layered ply for 12 mm (Additional 2 layers for other thickness)
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High shock and buckling resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Zero defects in flexure and edge loading</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 19mm, 12mm, 9mm &6mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available : Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face : Gurjan (with dipping)
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Core : All red hard core</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding : PF resin as per BIS standards</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : Above 750 kg/cm3</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content : 8-12%</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength : Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse : Excellent
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test : Confirms to IS:303</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per warranty certificate</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS : IS:303</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}>ECO-XL PLY: (IS:303) MR</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>With phenomenal highlights like high life span and mechanical strength, ASPL invests heavily in its ECO-XL Ply. This exceptionally strong pressed wood is an all-around preferred item because of numerous great credits like unrivalled screw holding limit, nail holding strength, uniform thickness, and significantly more. Aside from independence from distorting, it is water-safe It is constructed according to the IS:303 norms.</p>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/ECO-XL PLY.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling waterproof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling water resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>15 layered ply for 19 mm (Additional 2 layers for other thickness)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>13 layered ply for 16 mm (Additional 2 layers for other thickness)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>11 layered ply for 12 mm (Additional 2 layers for other thickness)
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High shock and buckling resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Zero defects in flexure and edge loading</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 19mm, 12mm, 9mm &6mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available : Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face : Gurjan (with dipping)
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Core : Alternate core</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding : MUF resin as per BIS standards</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : Above 650 kg/cm3</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content : 8-12%</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength : Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse : Excellent
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test : Confirms to IS:303</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per warranty certificate.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS : IS:303</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}>SILVER PLY(IS;303) MR</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SILVER Grade plywood is MR grade Commercial plywood with an alternative core according to the IS: 303 norms. It is constructed from an alternate core (hard and softcore) with MUF resin. This profoundly sturdy pressed wood is an all-around preferred item because of numerous grand credits like unrivalled screw holding limit, nail holding strength, uniform thickness, and considerably more. Aside from independence from twisting, it is likewise impervious to termites and drills .</p>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/ECO-XL PLY.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling water resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability    </p>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High shock and buckling resistance
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 18mm, 12mm, 9mm &6mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size : available Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Face : Gurjan (with dipping)
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Core : Alternate core</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding MUF resin as per : BIS standards.

</p>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : Above 500 kg/cm3</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content : 8-12%

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test : Confirms to IS:303
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per : warranty certificate.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS : IS:303</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Plywood