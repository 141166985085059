import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTestimonial() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="item">
                        <div className="testimonial-one__single">
                            <p className="testimonial-one__text-2">"Plywoods and gateway flush doors from Adarsh Sales Pvt. Ltd. satisfy all requirements." </p>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-details">
                                    <div className="testimonial-one__client-img">
                                        <img src="assets/ply/images (2).png" alt />
                                    </div>
                                    <div className="testimonial-one__client-content">
                                        <h4>Mr. Anurag Singh</h4>
                                        {/* <p> Vindhya Institute of Technology and Science</p> */}
                                    </div>
                                </div>
                                <div className="testimonial-one__quote">
                                    <span className="icon-quote" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="testimonial-one__single">
                            <p className="testimonial-one__text-2">"For the past 11 years, I have been using Adarsh Sales Pvt. Ltd, and I have always been happy with it."</p>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-details">
                                    <div className="testimonial-one__client-img">
                                    <img src="assets/ply/images (2).png" alt />
                                       
                                    </div>
                                    <div className="testimonial-one__client-content">
                                        <h4>Dr. K.P. Tripathi</h4>
                                        {/* <p>Client for Woodin</p> */}
                                    </div>
                                </div>
                                <div className="testimonial-one__quote">
                                    <span className="icon-quote" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="testimonial-one__single">
                            <p className="testimonial-one__text-2">It has been noted that the materials provided to various banks' authorised contractors by Adarsh Sales Pvt. Ltd. are of high quality.</p>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-details">
                                    <div className="testimonial-one__client-img">
                                    <img src="assets/ply/images (2).png" alt />
                                     
                                    </div>
                                    <div className="testimonial-one__client-content">
                                        <h4> Mr. Neeraj Tiwari</h4>
                                        {/* <p>Client for Woodin</p> */}
                                    </div>
                                </div>
                                <div className="testimonial-one__quote">
                                    <span className="icon-quote" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>



            </Swiper>
        </>
    );
}
