import React from 'react'

const OurJourney = () => {
    return (
        <>

            <section className="why-choose-one" style={{marginTop:"-150px", overflow:"hidden" }}>
                <div className="why-choose-one-shape float-bob-x">
                    <img src="assets/images/shapes/why-choose-one-shape-1.png" alt />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="why-choose-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                <div className="why-choose-one__img-box">
                                    <div className="why-choose-one__img">
                                        <img src="assets/images/resources/why-choose-one-img-1.jpg" alt />
                                        <div className="why-choose-one__dot float-bob-y">
                                            <img src="assets/images/shapes/why-choose-one-dot.png" alt />
                                        </div>
                                    </div>
                                    <div className="why-choose-one__img-two">
                                        <img src="assets/ply/post-2.jpg" alt />
                                    </div>
                                    <div className="why-choose-one__border" />
                                    <div className="why-choose-one__customer-service">
                                        <div className="why-choose-one__customer-service-icon">
                                            <span className="icon-emoji-chat-message" />
                                        </div>
                                        <div className="why-choose-one__customer-service-content">
                                            <h4>Excellent <br /> Customer Service</h4>
                                        </div>
                                        <div className="why-choose-one__customer-service-dot float-bob-x">
                                            <img src="assets/images/shapes/why-choose-one-customer-service-dot.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="why-choose-one__right">
                                <div className="section-title text-left">
                                    <br />
                                    <span className="section-title__tagline mt-lg-5" style={{ textTransform: "capitalize" }}>"We Enhance Your Life With World-class Luxury"</span>
                                    <h2 className="section-title__title" style={{fontSize:"50px"}}>Our Journey</h2>
                                </div>
                                <p className="why-choose-one__text-1" style={{ textAlign: "justify" }}>ADARSH is derived from a Hindi word meaning Ideal or Perfect. Maintaining the dignity of our name we are perfect with what we do. </p>
                                <br />
                                <p className="why-choose-one__text-2" style={{ textAlign: "justify" }}>The ADARSH group came into existence in the year 1982 under the aegis of Shri. Hanuman Prasad Gupta, a leader, a visionary and a philanthropist. The group began its operation by stepping into the furniture industry by the name of ADARSH FURNITURE CENTRE. Adarsh Furniture Centre was known as a local furniture shop and a furniture manufacturer. We were the first furniture shop in the Satna district. We used to manufacture furniture in the basement of the showroom at that time.</p>
                                <br />
                                <p className="why-choose-one__text-2" style={{ textAlign: "justify" }}>With the increase in demand, we need to increase our production, therefore in the year 1985, we shifted our workshop at the local industrial area and started a new firm by the name of “Wood Works”. This firm used to manufacturer a decent quantity of products to meet our requirement and to supply many ongoing projects at that time.</p>

                            </div>
                        </div>

                    </div>
                    <br />
                    <p className="why-choose-one__text-2" style={{ textAlign: "justify" }}>Adarsh Furniture Centre has also executed many tenders with companies like L&T, LIC, Heidelberg cement, MP Birla Corp, Reliance Cement, Our other two concerns “Adarsh Marketing” and “Adarsh Distributors” had been known for the authorized dealerships of Hindustan Unilever Ltd., Nestle and Nokia.</p>
                </div>
            </section>



        </>
    )
}

export default OurJourney