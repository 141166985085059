import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";



export default function SliderOurClients() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 5,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper container"
            >


                <SwiperSlide>
                    <li className="slide-item">
                        <figure className=""><a to="#"><img src="./assets/ply/logo_232.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className=""><a to="#"><img src="./assets/ply/MARKOLINES PAVEMENT LTD.JPG" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className=""><a to="#"><img src="./assets/ply/KALVAN TOLL INFRA.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className=""><a to="#"><img src="./assets/ply/VITS COLLEGE SAINA.png" alt /></a></figure>
                    </li>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <li className="slide-item">
                        <figure className="image-box"><a to="#"><img src="./assets/ply/WhatsappLogo.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className="image-box"><a to="#"><img src="./assets/ply/WhatsappLogo.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className="image-box"><a to="#"><img src="./assets/ply/WhatsappLogo.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className="image-box"><a to="#"><img src="./assets/ply/WhatsappLogo.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item">
                        <figure className="image-box"><a to="#"><img src="./assets/ply/WhatsappLogo.png" alt /></a></figure>
                    </li>
                </SwiperSlide> */}


            </Swiper>
        </>
    );
}
