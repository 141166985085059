import React from 'react'

const HomeMission = () => {
    return (
        <>

            <section className="famous-company-two" style={{overflow:"hidden"}}>
                <div className="container">
                    <div className="famous-company-two__top">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="famous-company-two__top-left">
                                    <h2 className="famous-company-two__title" style={{textAlign:"center", fontSize:"50px"}}>Our Mission & Vision</h2>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="famous-company-two__bottom">
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="famous-company__single">
                                    <div className="famous-company__icon">
                                        <img src="./assets/ply/Mission.png" alt />
                                    </div>
                                    <h3 className="famous-company__title"><a to="carpentry.html">Our Mission</a></h3>
                                    <p className="famous-company__text">To build the best product. To be the first preference of customers in terms of quality, finish and durability. To prove to the market that quality will always be preferred over price. </p>
                                    <div className="famous-company__shape-1" />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                <div className="famous-company__single">
                                    <div className="famous-company__icon">
                                        <img src="./assets/ply/Vision-.png" alt />
                                    </div>
                                    <h3 className="famous-company__title"><a to="about.html">Our Vision</a></h3>
                                    <p className="famous-company__text">To provide a world-class lifestyle experience to the clients. To be on the top of the market in terms of technology, advanced machinery and continuous innovation and research. </p>
                                   
                                    <div className="famous-company__shape-1" />
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeMission