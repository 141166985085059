import React from 'react'
import { Link } from "react-router-dom";


const FlushDoors = () => {
    return (
        <>


            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Flush Doors</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="index.html">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Flush Doors</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/Gateway Pine FD.JFIF" alt />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "28px" }}>Flush Door: Sandwich(IS:2202(PT-1)1999)</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>SANDWICH DOORS are the result of the innovation and dedication of our R&d Department. These Doors are constructed with 3mm HDHMR FACIA Skin on Both sides to provide a smoother finish. These doors provide the strength of normal flush doors and the finish of an HDHMR at once. We also provide an extra lock rail on both sides as extra support for modern technology locks. CNC Routing, DUCO, PU Paints are easily done in this segment for a smoother finish and high quality. </p>
                                        <ul className="list-unstyled project-detsils__challenge-list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>10 Year Guarantee</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>GuaranteeFully Water Proof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Premium (IS:2202(PT-1)1999)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Commercial Doors</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-draw-check-mark" />
                                                </div>
                                                <div className="text">
                                                    <p>Residential Doors</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling waterproof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High shock and buckling resistance
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Zero defects in flexure and edge loading</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}>FLUSH DOOR: PREMIUM (IS:2202(PT-1)1999)</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>Our PREMIUM segment Flush Doors comes as per IS: 2202 PT-1 (1999) and BWP grade. It is constructed from seasoned and chemically treated Hard Wood. Fillers are also well settled and to absorb the right amount of resin and provide a smooth surface. We also provide an extra lock rail on both sides as extra support for modern technology locks. The top surface is finished with a high-quality Gurjan face. Sanding and Dipping, in the end, provide excellent finish and chemical treatment.</p>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="./assets/ply/Gateway Pine FD.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling waterproof</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>No warping or bending</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Fungal, termite and borer treated
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Superior Stability and durability    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Dimensional accuracy</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High stability strength</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>High shock and buckling resistance
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Zero defects in flexure and edge loading</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 30mm to 45mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available : Available in all standard sizes</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Frame width : 75mm
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Lock rail width : 75mm (both side)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per warranty certificate</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS : IS:2202 (PT-1) 1999</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding : PF resin as per BIS standards</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : 700-750 kg/cm3</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Moisture content : 9-13%
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Glue adhesion strength : Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Resistance to misuse : Excellent</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test : Confirms to IS:2202(pt-1) 1999</p>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>
            </div>

        </>
    )
}

export default FlushDoors