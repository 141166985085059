import React from 'react'
import { Link } from "react-router-dom";


const Gallery = () => {
    return (
        <>


            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Our Gallery </h2>
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><span>/</span></li>
                            <li>Our Gallery </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="container">
                {/* <div className="section-title text-center">
                    <span className="section-title__tagline" style={{ textTransform: "capitalize" }}>Adarsh Sales Pvt. Ltd. </span>
                    <h2 className="section-title__title" style={{ fontSize: "50px" }}>Our Gallery</h2>
                </div> */}
                <div className="row" style={{ marginTop: "-0px" }}>
                    <div className="col-lg-12 related_productsection">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p1.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p8.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p9.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p2.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p3.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p4.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p5.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p6.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p7.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Gallery