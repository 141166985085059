import React from 'react'
import { Link } from "react-router-dom";


const LatestBlogs = () => {
    return (
        <>
            <section className="blog-one" style={{overflow:"hidden", marginTop:"-116px"}}>
                <div className="blog-one-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/blog-one-bg.jpg)' }} />
                <div className="container">
                    <div className="blog-one__top">
                        <div className="section-title text-center">
                            <span className="section-title__tagline" style={{textTransform:"capitalize"}}>Adarsh Sales Pvt. Ltd. </span>
                            <h2 className="section-title__title" style={{fontSize:"50px"}}>Latest Blogs</h2>
                        </div>
                        <div className="row">
                            {/*Blog One Single Start*/}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-5.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                            
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog">20-08-2024</Link>
                                            </li>
                                        </ul>
                                        {/* <h3 className="blog-one__title"><Link to="/blog">Adarsh Sales Pvt. Ltd.'s Guide to Original Office and Home Design: Unleashing Creativity</Link></h3> */}
                                        <h3 className="blog-one__title" style={{fontSize:"20px"}}><Link to="/blog">Adarsh Sales Pvt. Ltd.'s Guide to Original Office and Home Design</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Blog One Single End*/}
                            {/*Blog One Single Start*/}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-8.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                            
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog">24-08-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog" style={{fontSize:"20px"}}>The Manufacturing Process of Adarsh Sales Pvt. Ltd.</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Blog One Single End*/}
                            {/*Blog One Single Start*/}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-2 - Copy.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                            
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog">26-08-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog" style={{fontSize:"20px"}}>Sales Adarsh Pvt. Ltd. The Ultimate in Plywood Performance</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Blog One Single End*/}
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default LatestBlogs