import React from 'react'
import SliderProduct from '../../component/slidercomponent/SliderProduct'
import { Link } from "react-router-dom";


const Overview = () => {
  return (
    <>


      <section className="page-header">
        <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Overview </h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li><span>/</span></li>
              <li>Overview </li>
            </ul>
          </div>
        </div>
      </section>


      <section className="project-details" style={{ marginTop: "-147px" }}>
        <div className="container">


          <p className="project-details__challenge-text-3">We ADARSH SALES PVT. LTD. is one of the premier manufacturer and supplier of PLYWOOD, DOORS & BLOCKBOARDS is known for its extraordinary quality of products and exceptional service to its clients, distributors, and dealers. With the intention of quality first A-ADARSH PLY has come to represent a trusted brand with immaculate product integrity and market goodwill. Today, the company has a rock-solid foundation of state-of-the-art machinery and a devoted nationwide network of offices, distributors, dealers.</p>
          <p className="project-details__challenge-text-3">The business commenced with the establishment of our firm “Adarsh Furniture Centre” on November 13, 1982. Adarsh Furniture Centre is the oldest Furniture shop in the Satna district. Since 1982 we maintain our dignity and discipline in manufacturing top-quality products. With the increase in demand for Plywood and Flush Doors, Adarsh Sales Pvt. Ltd. was incorporated in 2009 to grab the opportunity to provide the best calibre products to the market.</p>
          <div className="project-details__challenge">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="project-details__challenge-left">
                  <div className="project-details__challenge-img">
                    <img src="assets/images/services/service-details-img-2.jpg" alt />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="project-details__challenge-right">
                  <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>Since the origin of our organization, it has been. our undertaking to stay at the cutting edge of advancement, and embrace the changing inclinations of the Indian shopper wholeheartedly. It is thus that we have continually endeavoured to add new items and administrations to our current item offering. We furnish our clients with a believed wellspring of top calibre, convenient conveyance and novel arrangements made with manageability. We trust in building dependable connections by offering greatness in assistance.</p>
                  <p className="project-details__challenge-text" style={{ textAlign: "justify", marginTop:"-50px" }}>We accept the fact that "A house is a fantasy that needs to come true". We endeavour to give our clients the Best-in-Performance items that are reasonably sourced, top-notch, and stylishly engaging so their fantasies work out.</p>
                </div>
              </div>
            </div>



          </div>
          <div className="project-details__challenge" style={{marginTop:"-10px"}}>
            <div className="row">

              <div className="col-xl-6 col-lg-6">
                <div className="project-details__challenge-right">
                  <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>The company’s products occupy an enviable position in the Indian marketplace and have multifarious applications – Ranging from household to buildings to construction. It is one of those plywood company that believes in Consumer Satisfaction. The company’s in-house research and technology ensures not just consistency in product quality but also continuous improvements in products, processes, and application areas. Our R&D ensures constant improvement of existing product and innovation of new ones to offer better, offer more to our customer. Moreover, our process is eco-friendly and energy-efficient with an in-house seasoning plant, measures that ensure the health and safety of our manpower. Our in-house production of resin helps in stronger bonding and increase the durability of the product and making the product waterproof.</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="project-details__challenge-left">
                  <div className="project-details__challenge-img">
                    <img src="assets/images/project/project-details-challenge-img.jpg" alt />
                  </div>
                </div>
              </div>
              <p className="project-details__challenge-text" style={{ textAlign: "justify",marginTop:"-10px" }}>We believe that quality is not an act, it is a habit, and throughout our journey, we only focus on manufacturing quality products. Each batch that bears the A-ADARSH name is checked as per BIS norms and EUROPEAN STANDARDS before being released in the market. At A-Adarsh Ply we have always believed that our quality and service will help us succeed. Adarsh ply products enjoy the trust, confidence and patronage of all their customers and influencers. Our affiliation, association and dedication to quality procedures and willingness to innovate and be the best have carved a niche in the hearts and mind space of all our customers and trade partners.</p>

            </div>



          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="project-details__end-reasult">
                <h4 className="project-details__end-reasult-title">Adarsh Sales Pvt. Ltd.</h4>
                <p className="project-details__end-reasult-text">We ADARSH SALES PVT. LTD. is one of the premier manufacturer and supplier of PLYWOOD, DOORS & BLOCKBOARDS is known for its extraordinary quality of products and exceptional service to its clients, distributors, and dealers. With the intention of quality first A-ADARSH PLY has come to represent a trusted brand with immaculate product integrity and market goodwill. Today, the company has a rock-solid foundation of state-of-the-art machinery and a devoted nationwide network of offices, distributors, dealers.</p>
                <p className="project-details__end-reasult-text">Today A- ADARSH PLY is the unprecedented choice of Architects, Interior Designer & contractors. They strongly recommend our products for transforming ordinary steel and concrete structure to the expression of one’s personality. We are guided by a simple philosophy of adding more value for money, products and at the same time, improving upon our existing product portfolio through extensive research and most important costumer’s feedback. Every product is strictly inspected because: “WE NEVER COMPROMISE WITH QUALITY”.</p>
              </div>
            </div>
          </div>
       
        </div>
      </section>


    </>
  )
}

export default Overview