import React, { useState } from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';
import Footer from '../../component/layout/Footer';
import emailjs from "@emailjs/browser";




const Careers = () => {

    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [subject, setSubject] = useState("");
    const [city, setCity] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const stateChange = (e) => {
        const value = e.target.value;
        setState(value);
    };
    const cityChange = (e) => {
        const value = e.target.value;
        setCity(value);
    };
    const subjectChange = (e) => {
        const value = e.target.value;
        setSubject(value);
    };
    const numberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    };

    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(

                "service_oumku8k",
                "template_esy4tqm",
                "#myForm",
                "8i7i_APkS7sZ9CqPl"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setmail("");
                        setMassages("");
                        setNumber("");
                        setState("");
                        setSubject("");
                        setCity("");

                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>

            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Careers</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Careers</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="contact-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5">
                                <div className="contact-page__left">
                                    <h4 className="contact-page__title" style={{ fontSize: "34px" }}>Feel free to ask questions or share your message with us.
                                    </h4>

                                    <ul className="list-unstyled contact-page__list">
                                        <li>
                                            <div className="icon">
                                                <span className="icon-location" />
                                            </div>
                                            <div className="content">
                                                <h5>Corporate Office</h5>
                                                <p>Wood Work , In Front of Sitaram Petrol Pump, Rewa Road Satna (M.P.)</p> <br />
                                                <h5>Registered Office & Factory</h5>
                                                <p> Nh-75, Satna – Panna Road, In Front Of Bagri Petrol Pump, Vill. Hardua Majhol, P.o Sitpura, Satna (M.P.), 485441</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-phone" />
                                            </div>
                                            <div className="content">
                                                <h5>Phone</h5>
                                                <p>
                                                    <a href="tel:+919977155591">+91- 9977155591</a>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-email" />
                                            </div>
                                            <div className="content">
                                                <h5>Email Address</h5>
                                                <p>
                                                    <a href="mailto:aspl2009@yahoo.in">aspl2009@yahoo.in</a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="contact-page__right">
                                    <h3 className="contact-page__right-title">Send a message</h3>
                                    <form method="POST" onSubmit={sendEmail} id="myForm" className="contact-page__form contact-form-validated" noValidate="novalidate">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="text" placeholder="Name"
                                                        name="from_name"
                                                        onChange={(e) => nameChange(e)}
                                                        required
                                                        value={name}
                                                        id="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="email" placeholder="Email Address"
                                                        id="email"
                                                        name='email'
                                                        onChange={(e) => mailChange(e)}
                                                        value={mail}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="text" placeholder="Phone"
                                                        id="phone"
                                                        name='phone'
                                                        onChange={(e) => numberChange(e)}
                                                        value={number}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="text" placeholder="State"
                                                        id="state"
                                                        name="state"
                                                        onChange={(e) => stateChange(e)}
                                                        value={state}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="text" placeholder="Subject"
                                                        name="subject"
                                                        id="Subject"
                                                        onChange={(e) => subjectChange(e)}
                                                        value={subject}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="contact-page__input-box">
                                                    <input type="text" placeholder="City"
                                                        id="city"
                                                        name="city"
                                                        onChange={(e) => cityChange(e)}
                                                        value={city}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="contact-page__input-box text-message-box">
                                                    <textarea placeholder="Type Messages"
                                                        id="address"
                                                        onChange={(e) => massagesChange(e)}
                                                        name='massage'
                                                        value={massages}
                                                        required
                                                    />
                                                </div>
                                                <div className="contact-page__btn-box">

                                                    <button type="submit" className="contact-page__btn">Send A Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               

            </div>




        </>
    )
}

export default Careers