import React from 'react'
import SliderOurClients from '../../component/SliderOurClients'

const OurClients = () => {
    return (
        <>

            <section className="clients-section outnews_section" style={{ overflow: "hidden" }}>
                <div className="auto-container">
                    <div className="section-title text-center">
                        <h2 className="section-title__title" style={{ fontSize: "50px", marginTop:"20px" }}>Our clients</h2>
                    </div>
                    <div className="inner-container mt-lg-5">
                        <div className="sponsors-outer">
                            <ul className="sponsors-carousel owl-theme">
                                <SliderOurClients />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            

        </>
    )
}

export default OurClients