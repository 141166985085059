import React from 'react'
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner'

const HomeBanner = () => {
    return (
        <>
        <SliderHomeBanner />
        </>
    )
}

export default HomeBanner