import React from 'react'
import { Link } from "react-router-dom";
import LatestBlogs from '../home/LatestBlogs';



const Blog = () => {
    return (
        <>

            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Latest Blogs </h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Latest Blogs </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="blog-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-5.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                           
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">20-08-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>Adarsh Sales Pvt. Ltd.'s Guide to Original Office and Home Design</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-8.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                          
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">24-08-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>The Manufacturing Process of Adarsh Sales Pvt. Ltd.</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-3.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                           
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">26-08-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>Sales Adarsh Pvt. Ltd. The Ultimate in Plywood Performance with Robusta Premium Plus.</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-4.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                          
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">30-12-2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>How to Take Care of Plywood Furniture</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="500ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-1.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                           
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">25-01-2025</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>What is the greatest plywood brand? </Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="600ms">
                                <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src="assets/ply/post-6.jpg" alt />
                                    </div>
                                    <div className="blog-one__content">
                                        <ul className="list-unstyled blog-one__meta">
                                           
                                            <li>
                                                <i className="icon-clock" />
                                                <Link to="/blog_details_1">20-02-2025</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-one__title"><Link to="/blog_details_1" style={{fontSize:"20px"}}>Which type of plywood is the strongest?</Link></h3>
                                        <div className="blog-one__arrow">
                                            <Link to="/blog_details_1">Learn More <span className="icon-right-arrow-1" /></Link>
                                        </div>
                                        <div className="blog-one__tag">
                                            <p>Adarsh Sales Pvt. Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



        </>
    )
}

export default Blog