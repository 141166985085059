import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>
      <footer className="site-footer" style={{overflow:"hidden"}}>
        <div className="site-footer-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/site-footer-bg.jpg)' }}>
        </div>
        <div className="site-footer__top">
          <div className="container">
            <div className="row">

              <div className="col-xl-4 col-lg-6 col-md-6" >
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__logo">
                    {/* <a to="index.html"><img src="assets/images/resources/footer-logo.png" alt /></a> */}
                    <Link to="/"><img src="./assets/ply/ply.png"  style={{width:"150px", height:"150px"}}alt /></Link>
                  </div>
                  <div className="footer-widget__about-text-box">
                    <p className="footer-widget__about-text" style={{ color: "white" }}>Manufacturing plywood blended with reliability and made from world-class raw materials for a bond that lasts forever. </p>
                  </div>
                  <div className="site-footer__social">
                    <a style={{ color: "white" }} href="https://www.instagram.com/adarshply?igsh=MXVscm9uN3drbHdrbA==" target="_blank"><i className="fab fa-instagram" /></a>
                    <a style={{ color: "white" }} href="https://www.facebook.com/profile.php?id=61555621764593" target="_blank"><i className="fab fa-facebook-f" /></a>
                    {/* <a style={{ color: "white" }} to="#"><i className="fab fa-youtube" /></a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6" >
                <div className="footer-widget__column footer-widget__service clearfix">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Quick Links</h3>
                  </div>
                  <ul className="footer-widget__service-list list-unstyled clearfix" style={{ color: "white" }}>
                    <li><Link style={{ color: "white" }} to="/">Home</Link></li>
                    <li><Link style={{ color: "white" }} to="/overview">Overview</Link></li>
                    <li><Link style={{ color: "white" }} to="/contact-us">Contact Us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6" >
                <div className="footer-widget__column footer-widget__service clearfix">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Our Products</h3>
                  </div>
                  <ul className="footer-widget__service-list list-unstyled clearfix" style={{ color: "white" }}>
                    <li><Link style={{ color: "white" }} to="/flush-doors">Flush Doors</Link></li>
                    <li><Link style={{ color: "white" }} to="/block-boards">Block Boards</Link></li>
                    <li><Link style={{ color: "white" }} to="/plywood">Ply Wood</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6" >
                <div className="footer-widget__column footer-widget__contact clearfix">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Contact Us</h3>
                  </div>
                  <ul className="footer-widget__contact-list list-unstyled clearfix" style={{ color: "white" }}>
                    <li>
                      <div className="icon">
                        <span className="icon-email-1" />
                      </div>
                      <div className="text" style={{ color: "white" }}>
                        <p style={{ color: "white" }}><a href="mailto:aspl2009@yahoo.in" style={{ color: "white" }} target="_blank">aspl2009@yahoo.in</a></p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-phone" />
                      </div>
                      <div className="text" style={{ color: "white" }}>
                        <p><a href="tel:+91 9977155591" style={{ color: "white" }} target="_blank">+91 9977155591</a></p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-location" />
                      </div>
                      <div className="text">
                        <p > <a style={{ color: "white" }} href='https://maps.app.goo.gl/w16QjmcwF8trMZ8n8?g_st=ac' target="_blank"> Wood Work , In Front of Sitaram Petrol Pump, Rewa Road Satna (M.P.)</a></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="site-footer__bottom-inner">
                  <p className="site-footer__bottom-text">© <a style={{ color: "white" }} href="tel:+91 8982471422"> 2025 Adarsh Sales Pvt. Ltd. All Rights Reserved</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>



      <WhatsappIcon />



    </>
  )
}

export default Footer