import React from 'react'
import { Link } from "react-router-dom";


const HomeAbout = () => {
    return (
        <>
            <section className="why-choose-two" style={{marginTop:"-90px", overflow:"hidden" }}>
                <div className="why-choose-two-shape-1 float-bob-x">
                    <img src="assets/images/shapes/why-choose-two-shape-1.png" alt />
                </div>
                <div className="why-choose-two-shape-2 float-bob-y">
                    <img src="assets/images/shapes/why-choose-two-shape-2.png" alt />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="why-choose-two__left">
                                <div className="section-title-two text-left">
                                    <span className="section-title-two__tagline">Adarsh Sales Pvt. Ltd.</span>
                                    <h2 className="section-title-two__title">About Us</h2>
                                </div>
                                <p className="why-choose-two__text-1" style={{textAlign:"justify"}}>We ADARSH SALES PVT. LTD. Is One Of The Strongest Manufacturer And Supplier Of PLYWOOD, DOORS & BLOCK-BOARDS In The Indian Domestic Market. With The Intention Of Quality First ADARSH SALES PVT. LTD. Is Proudly Serving It’s Costumer Since 2009. Our Plant Is Equipped With State-of-the-art Equipment And Testing Facilities Making Sure The Quality Of Product During Every Process Of Manufacturing, To Produce A Product According To International Standards. Our In-house Production Of Resin Helps In Stronger Bonding And Increase The Durability Of Product, Making The Product Waterproof. Our R&D Ensures Constant Improvement Of Existing Product And Innovation Of New Ones To Offer Better, Offer More To Our Costumer.</p>
                                <p className="why-choose-two__text-1" style={{textAlign:"justify"}}>Moreover, Our Process Is Eco-friendly And Energy Efficient With An In-house Seasoning Plant, Measurers That Ensures The Health And Safety Of Our Manpower. Each Batch That Bears The ADARSH Name Is Checked As Per BIS Norms And EUROPEAN STANDARDS Before Being Released In The Market.</p>
                                
                                <div className="why-choose-two__unique-work">
                                    <div className="why-choose-two__unique-work-icon">
                                        <span className="icon-circular-saw-1" />
                                    </div>
                                    <div className="why-choose-two__unique-work-content">
                                        <p className="why-choose-two__unique-work-text">"We Enhance Your Life With World-class Luxury"</p>
                                    </div>
                                </div>
                              
                            
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="why-choose-two__right">
                                <div className="why-choose-two__img-box wow slideInRight mt-lg-5" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <br/>
                                    <div className="why-choose-two__img">
                                        <img src="assets/ply/post-7.jpg" alt />
                                    </div>
                                    {/* <div className="why-choose-two__img-two">
                                        <img src="assets/images/resources/why-choose-two-img-2.jpg" alt />
                                    </div>
                                    <div className="why-choose-two__customer-service">
                                        <div className="why-choose-two__customer-service-icon">
                                            <span className="icon-emoji-chat-message" />
                                        </div>
                                        <div className="why-choose-two__customer-service-content">
                                            <h4>Excellent <br /> Customer Service</h4>
                                        </div>
                                        <div className="why-choose-two__customer-service-dot float-bob-x">
                                            <img src="assets/images/shapes/why-choose-one-customer-service-dot.png" alt />
                                        </div>
                                    </div> */}
                                    <div className="why-choose-two__dot">
                                        <img src="assets/images/shapes/why-choose-two-dot.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeAbout