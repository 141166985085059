import React from 'react'
import SliderProcess from '../../component/slidercomponent/SliderProcess'

const Process = () => {
    return (
        <>
            <section className="project-two" style={{overflow:"hidden"}}>
                <div className="project-two__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7">
                                <div className="project-two__left">
                                    <div className="section-title-two text-left">
                                        <span className="section-title-two__tagline">Completed Project</span>
                                        <h2 className="section-title-two__title" style={{fontSize:"50px"}}>Our Process</h2>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-5 col-lg-5">
                                <div className="project-two__right">
                                    <p className="project-two__right-text">We Manufacture Products That Meet All Stringent Ecological Standards. Each Sheet Experiences Thorough Quality Check Before Being Sent To Market. All Things Considered, We Invest Heavily In Offering Our Shoppers Unrivalled Quality Items, While Remembering The “esteem For Cash” Remainders.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <p className="project-two__right-text container" style={{textAlign:"justify"}}>We Manufacture Products That Meet All Stringent Ecological Standards. Each Sheet Experiences Thorough Quality Check Before Being Sent To Market. All Things Considered, We Invest Heavily In Offering Our Shoppers Unrivalled Quality Items, While Remembering The “esteem For Cash” Remainders.</p>

                <div className="project-two__bottom">
                    <div className="project-two__container">
                        <div className="owl-theme thm-owl__carousel project-two__carousel">
                            <SliderProcess />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Process