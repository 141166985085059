import React from 'react'
import { Link } from "react-router-dom";


const ShutteringPly = () => {
    return (
        <>


            <div>
                <section className="page-header">
                    <div className="page-header-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/page-header-bg.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Shuttering Ply</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><Link to="index.html">Home</Link></li>
                                <li><span>/</span></li>
                                <li>Shuttering Ply</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="project-details" style={{ marginTop: "-140px" }}>
                    <div className="container">
                        <div className="project-details__challenge">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-left">
                                        <div className="project-details__challenge-img">
                                            <img src="../assets/ply/Weight.jpg" alt />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__challenge-right">
                                        <h4 className="project-details__challenge-title" style={{ fontSize: "28px" }}>SHUTTERING PLY(IS:4990) PF RESIN</h4>
                                        <p className="project-details__challenge-text" style={{ textAlign: "justify" }}>ASPL shuttering ply is BWP grade plywood, treated with preservatives. Manufactured with selected hardwood, this ply withstands the load and forces encountered during the pouring of concrete and vibration. It renders a better economy for its repeated usage compared to other plywood varieties.</p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Unique features </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Boiling water resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>More repetition than ordinary plywood</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Tough bonding
                                                    </p>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Termite resistance</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Chemically treated and seasoned as per BIS</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                    <h4 className="project-details__challenge-title" style={{ fontSize: "25px" }}> Specifications </h4>
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Thickness available : 12mm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Size available : 8*4</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Adhesive for bonding : PF resin as per BIS standards
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Density : Above 700 kg/cm3.</p>
                                                </div>
                                            </li>
                                         
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="project-details__bottom-left">
                                        <ul className="list-unstyled project-details__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Weight : 30kg</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Water-resistance test : Confirms to IS:4990

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>Warranty : Any manufacturing defects as per warranty certificate
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>IS STANDARDS : IS:4990</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-right-arrow-2" />
                                                </div>
                                                <div className="text">
                                                    <p>CONSTRUCTION AND CIVIL WORKS</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default ShutteringPly