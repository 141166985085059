import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';


import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
                style={{overflow:"hidden"}}
            >

              
                <SwiperSlide>
                    <div className="">
                        <img src="./assets/ply/1.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
               
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/2.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/3.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_02.jpg" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
               
                

            </Swiper>
        </>
    );
}
